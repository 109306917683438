<template>
    <div class="layout">
        <div v-if="$route.name == 'TerminalAnswerScreen'
                || $route.name == 'TerminalAnswer'|| $route.name == 'TerminalEnd' || $route.name == 'TerminalHuaBan'">
            <div class="content">
                <router-view :key="key"/>
            </div>
        </div>
        <div v-else>
            <top-bar  v-if="$route.name !== 'ReportPage'"/>
            <div class="container">
                <slide-bar
                    v-if="$route.name !== 'AssessDetail'
                  && $route.name !== 'messagePage' && $route.name !== 'ReportPage'
            "/>
                <div class="content">
                    <router-view :key="key"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import slideBar from '../components/sideBar'
import topBar from "../components/topBar";

export default {
    name: "Layout",
    components: {slideBar, topBar},
    data() {
        return {};
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        }
    },
    watch: {
        '$route.path':function (){
            //获取权限列表
            this.$store.dispatch('GetAuth')
        }
    },
    methods: {},
    mounted() {
    },
}
</script>

<style scoped>
.layout {
    width: 100vw;
    height: 100vh;
    background: #ECF1EB;
}

.container {
    margin: 15px 25px 15px 0px;
    height: calc(100vh - 150px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.content_answer {
    width: 100%;
    height: calc(100% + 150px);
    /*background-color: #fff;*/
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
}
.content {
    width: 100%;
    height: 100%;
    /*background-color: #fff;*/
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
}
</style>
